'use client';
import { memo } from 'react';
import dynamic from 'next/dynamic';

import { DISPLAY_MODE, DRAW_STYLE } from '@/constant/Theme.constant';
import { ThemeSelectors, useAppSelector } from '@/store';

import HomeLayoutLoading from './Home.layout.loading';

const HomeFullCircle = dynamic(() => import('./home1.circle'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});
const HomeFullSquare = dynamic(() => import('./home1.square'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});
const HomeTopCircle = dynamic(() => import('./home2.circle'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});
const HomeTopSquare = dynamic(() => import('./home2.square'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});

const HomeLeftCircle = dynamic(() => import('./Home.left.circle'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});

const HomeRightCircle = dynamic(() => import('./Home.right.circle'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});

const HomeRightSquare = dynamic(() => import('./Home.right.square'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});

const HomeLeftSquare = dynamic(() => import('./Home.left.square'), {
	ssr: false,
	loading: () => <HomeLayoutLoading />,
});

const HomeView = ({}: {}) => {
	const themeState = useAppSelector(ThemeSelectors.themeSelector);
	if (themeState?.currentDial?.name === DRAW_STYLE.DRAW_CIRCLE) {
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_TOP) {
			return <HomeTopCircle />;
		}
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_FULL) {
			return <HomeFullCircle />;
		}
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_LEFT) {
			return <HomeLeftCircle />;
		}
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_RIGHT) {
			return <HomeRightCircle />;
		}

		return <HomeFullCircle />;
	}

	if (themeState?.currentDial?.name === DRAW_STYLE.DRAW_SQUARE) {
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_TOP) {
			return <HomeTopSquare />;
		}
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_FULL) {
			return <HomeFullSquare />;
		}
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_LEFT) {
			return <HomeLeftSquare />;
		}
		if (themeState?.currentLayout?.name === DISPLAY_MODE.LAYOUT_RIGHT) {
			return <HomeRightSquare />;
		}
		return <HomeFullCircle />;
	}

	return <HomeFullSquare />;
};

export default memo(HomeView);
