import { memo } from 'react';

const HomeLayoutLoading = () => {
	return (
		<>
			<div className="h-[230px]"></div>
			<div className="row mx-auto w-[600px] gap-6">
				<div className="h-[184px] w-[184px] animate-pulse rounded-full bg-slate-50 bg-opacity-20"></div>
				<div className="h-[184px] w-[184px] animate-pulse rounded-full bg-slate-50 bg-opacity-20"></div>
				<div className="h-[184px] w-[184px] animate-pulse rounded-full bg-slate-50 bg-opacity-20"></div>
			</div>
		</>
	);
};

export default memo(HomeLayoutLoading);
