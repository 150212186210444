'use client';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { MODE } from '@/constant';
import { useI18n } from '@/locales/client';
import { useAppSelector } from '@/store';
import { GeneralActions, GeneralSelectors } from '@/store/General';
import { cn } from '@/utils';

const ModeButton = () => {
	const { t } = useI18n();
	const dispatch = useDispatch();
	const mode = useAppSelector(GeneralSelectors.selectMode);
	const ref: any = useRef();

	const onToggleMode = useCallback((mode: MODE) => {
		dispatch(GeneralActions.setMode(mode));
	}, []);

	useEffect(() => {
		if (ref?.current) {
			const leftValue = mode === MODE.Basic ? 78 : 4;
			ref.current.style.left = `${leftValue}px`;
		}
	}, [mode]);

	return (
		<div className="fixed bottom-4 left-4 z-50 md:bottom-10 md:left-10">
			<div className="flex h-[41px] w-[164px] items-center justify-between rounded-full bg-white p-1">
				<div
					className={cn(
						'center z-10 flex-1 cursor-pointer px-[6px] duration-300',
						mode === MODE.Dial ? 'text-white' : 'text-black'
					)}
					onClick={() => onToggleMode(MODE.Dial)}
				>
					{t('mode.dial')}
				</div>
				<div
					className={cn(
						'center z-10 flex-1 cursor-pointer px-[6px] duration-300',
						mode === MODE.Basic ? 'text-white' : 'text-black'
					)}
					onClick={() => onToggleMode(MODE.Basic)}
				>
					{t('mode.basic')}
				</div>
				<div
					ref={ref}
					className="absolute h-[calc(100%_-_8px)] w-[50%] rounded-full bg-black duration-300"
				></div>
			</div>
		</div>
	);
};

export default ModeButton;
